import React from "react"
import Ecart_chat_logo_for_header from "../assets/images/Ecart_chat_logo_for_header.png"
import Icon_email from "../assets/images/Icon_email.png"
import Icon_mobile from "../assets/images/Icon_mobile.png"
import Icon_location from "../assets/images/Icon_location.png"
import SM_fb from "../assets/images/SM_fb.png"
import SM_g_ from "../assets/images/SM_g_.png"
import SM_tw from "../assets/images/SM_tw.png"

export const Footer = () => {
  return (
    <footer className="w-100">
      <div className="container d-flex justify-content-between p-4 wrapper">
        <div className="column1">
          <img className="py-4 logoIcon" src={Ecart_chat_logo_for_header} />
          <br />
          <p className="m-0">
            <a
              target="_blank"
              href="mailto:info@ecart.chat"
              className="text-muted text-decoration-none my-3 mr-4"
            >
              <img className="icon" src={Icon_email} />
              &nbsp; info@ecart.chat
            </a>
          </p>
          <br />
          <a
            target="_blank"
            href="callto:+919633262579"
            className="text-decoration-none text-muted my-3 mr-4"
          >
            <img className="icon" src={Icon_mobile} />
            &nbsp; +91 9633 262 579
          </a>
          <br />
          <p className="text-muted py-3 pr-4">
            <img className="icon" src={Icon_location} />
            &nbsp; Kerala Startup Mission, ISC, KTIZ <br />
            Kochi, Kerala 683503
          </p>
        </div>
        <div className="column2 py-4">
          <p className="text-light pt-1">Features</p>
          <a className="text-muted text-decoration-none" href>
            Reminder mail
          </a>
          <br />
          <a className="text-muted text-decoration-none" href>
            Abandoned followup
          </a>
          <br />
          <a className="text-muted text-decoration-none" href>
            Subscriptions
          </a>
          <br />
          <a className="text-muted text-decoration-none" href>
            Cart followup mail
          </a>
          <br />
          <a className="text-muted text-decoration-none" href>
            Offer pussings
          </a>
          <br />
          <a className="text-muted text-decoration-none" href>
            Campaigns
          </a>
        </div>
        <div className="column3 py-4">
          <p className="text-light pt-1">Legal</p>
          <a className="text-muted text-decoration-none" href>
            Copyright
          </a>
          <br />
          <a className="text-muted text-decoration-none" href>
            Terms of use
          </a>
          <br />
          <a className="text-muted text-decoration-none" href>
            Privacy policy
          </a>
        </div>
        <div className="column4 py-4">
          <p className="text-light pt-1">Company</p>
          <a className="text-muted text-decoration-none" href>
            About Us
          </a>
          <br />
          <a className="text-muted text-decoration-none" href>
            Contact Us
          </a>
          <br />
          <a className="text-muted text-decoration-none" href>
            Careers
          </a>
          <br />
          <a className="text-muted text-decoration-none" href>
            Support
          </a>
          <br />
          <a className="text-muted text-decoration-none" href>
            Pricing
          </a>
        </div>
        <div className="column5 py-4 pl-md-3">
          <a href>
            <img src={SM_fb} />
          </a>
          <a href>
            <img src={SM_g_} />
          </a>
          <a href>
            <img src={SM_tw} />
          </a>
        </div>
      </div>
    </footer>
  )
}
