import React, { useState } from "react"
import Ecart_chat_logo_for_header from "../assets/images/Ecart_chat_logo_for_header.png"
import { Link } from "gatsby"

export const Header = () => {
  const [collapsed, setCollapsed] = useState(false)
  const [subCollapsed, setSubCollapsed] = useState(false)

  return (
    <>
      <div className="position-absolute w-100 header-top">
        <div className="container ">
          <nav className="navbar navbar-expand-lg mt-5 align-items-end navbar-dark">
            <Link className="navbar-brand pb-2 align-self-start" to="/">
              <img src={Ecart_chat_logo_for_header} />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarMobileDropdown"
              aria-controls="navbarSupportedContent"
              aria-expanded="true"
              aria-label="Toggle navigation"
              onClick={() => {
                setCollapsed(!collapsed)
              }}
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className={`navbar-collapse collapse ${!collapsed ? "" : "show"}`}
              id="navbarMobileDropdown"
              style={{}}
            >
              <div className="menu-main-menu-container">
                <ul id="menu-main-menu" className="navbar-nav ml-lg-5">
                  <li
                    id="menu-item-20"
                    className="nav-item "
                  >
                    <Link className="nav-link"  activeClassName="active" to="/">
                      Home
                    </Link>
                  </li>
                  <li
                    id="menu-item-21"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-21 nav-item"
                  >
                    <Link className="nav-link" activeClassName="active" to="/blog">
                      Blog
                    </Link>
                  </li>
                  <li
                    id="menu-item-22"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-22 nav-item"
                  >
                    <a
                      className="nav-link"
                      href="#"
                      onclick={() => {
                        setSubCollapsed(!collapsed)
                      }}
                    >
                      Products
                    </a>
                    <ul className="sub-menu">
                      <li
                        id="menu-item-24"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24 nav-item"
                      >
                        <Link className="nav-link" activeClassName="active" to="/product">
                          Widget
                        </Link>
                      </li>
                      <li
                        id="menu-item-23"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-23 nav-item"
                      >
                        <Link className="nav-link" activeClassName="active" to="/emailRetarget">
                          Email Retarget
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>{" "}
              <a
                href="https://app.ecart.chat/auth"
                className="login-btn ml-auto"
              >
                Login
              </a>
              <a
                href="https://app.ecart.chat/auth/register"
                className="btn btn-primary orangeBG"
                onclick="location.href='https://app.ecart.chat/auth/register';"
              >
                Sign Up
              </a>
            </div>
          </nav>
        </div>
      </div>
    </>
  )
}
