import React from "react"
import "bootstrap-css-only/css/bootstrap.min.css"
import "../assets/css/style.css"
import "aos/dist/aos.css"
import "font-awesome/css/font-awesome.min.css"
import "font-awesome/fonts/fontawesome-webfont.ttf"

import { Header } from "./header"
import { Footer } from "./footer"

export const Layout = props => {
  return (
    <>
      <Header />
      {props.children}
      <Footer />
    </>
  )
}
